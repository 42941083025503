<template>
  <div class="container">

    <Header></Header>


    <div class="ny-main">

      <div class="cat-banner-wrap">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div v-for="(item, i) in banner" :key="i" class="swiper-slide">
			  <router-link :to="{name:'category',query:{'category':item.url_name}}">
              <!-- 具体内容 -->
              <img :src="item.banner_url" alt="商品图片">
              <span class="cat-banner-name">{{item.category_name}}</span>
			  </router-link>
            </div>
          </div>
        </div>


      </div>
      <!-- cat-banner-wrap end -->
      <div class="car-m1">
        <div class="car-m1-left">
          <h3>Customizable 99% of the hotel supplies</h3>
          <p>We aim to help you get the best quality hotel products for less.</p>
          <div class="car-m1-left-btnBox cl">
            <div class="car-m1-left-btn1 fl" @click="Download(download_url)">
              <span><img src="https://api.starmaxintl.com/storage/file/20220823/3f134faabeb37bc05d8687183cdf6ecc1d4bd8d4.png" alt=""></span>
              <p>Download</br>Brochurr</p>
            </div>
            <div class="car-m1-left-btn2 fl">
              Consulting</br>Customization
            </div>

          </div>

        </div>
        <div class="car-m1-right">
          <p>STARMAX's extensive product line can meet all your hotel bedding, toiletries, personal care, and hotel furniture needs, from towels, sheets, decorative bedspreads, and bulk and bottled amenities to hotel furniture such as carpets, flooring, curtains, furniture cabinets, doors, and windows. </p>
		  <p>We can customize 99% of the hotel products on the market and change any part to suit your actual needs.</p>
        </div>


      </div>
      <!-- car-m1 end -->

      <div class="cat-con">
        <div class="cat-con-head">
          <h3>{{category_name}}</h3>
          <p>Cost saving & High quality</p>
        </div>
        <div class="cat-conBox-wrap" v-for="(item,index) in goodslist">
          <div class="cat-conBox">
            <p>{{item.name}}</p>
            <h4>Customizable to meet any need</h4>
            <div class="cat-conTab">
              <div class="cat-conBox-left" >
                <ul class="cat-leftUl" :id="'cat-conBox-left-'+index">
				  <li :id="'cat-left-li-'+index+t" :class="t == 0 ?'active':' '" v-for="(icon,t) in item.goods_img" :key="t" @click="tabClick(index,t)">
                    <i class="cat-leftUl-icon"><img :src="icon.icon_url" alt=""></i>
                    <span>{{icon.iconname}}</span>
                  </li>
                </ul>
              </div>
              <div class="cat-conBox-right" :id="'cat-conBox-right-'+index">
                <div class="tabItem" :class="a == 0 ?'isShow':' '" :id="'cat-right-li-'+index+a" v-for="(img, a) in item.goods_img" :key="a">
                  <div class="tabItem-box">
                    <div class="tabItem-img1" :style="{backgroundImage:'url('+ img.top_url +')'}" height="1000px">
                      <div class="tabItem-bar" data-flag="0"></div>
                    </div>
                    <div class="tabItem-img2" :style="{backgroundImage:'url('+ img.bottom_url +')'}"></div>
                  </div>
                  <!-- tabItem-box end -->
                  <div class="car-m1-left-btn1 fl">
                    <span><img src="https://api.starmaxintl.com/storage/file/20220823/3f134faabeb37bc05d8687183cdf6ecc1d4bd8d4.png" alt=""></span>
                    <p>Download</br>Brochurr</p>
                  </div>
                </div>


              </div>
              <!-- cat-conBox-right end -->
            </div>
            <!-- cat-conTab end -->
          </div>
        </div>
        <!-- cat-conBox-wrap end -->

      </div>
      <!-- cat-con end -->
      <div class="cat-m2">
        <div class="cat-m2-top">
          <el-row>
            <el-col :span="11">
               <h3>See Successful Projects</h3>
            </el-col>
            <el-col :span="13">
              <div class="cat-m2-topText"><p>With us, find high-quality linen, amenities, furniture, and other products, at affordable prices, for the rooms in your hotel.</p></div>
            </el-col>
          </el-row>
        </div>

        <div class="cat-m2-picBox">
          <!--<div class="cat-m2-linkBtn" >Click for details</div>-->
          <img src="https://api.starmaxintl.com/storage/file/20220823/7287ff4d573e8e65842d2ed0a46ef78a6fb2d017.png" alt="" class="cat-m2-arrow">
          <div class="swiper-container2">
            <div class="swiper-wrapper">
              <div class="swiper-slide"  v-for="(item,e) in project" :key="e">
                  <router-link :to="{name:'project-detail',query:{'id':item.content_id}}"><img :src="item.thumb_url" /></router-link>
              </div>
                
            </div>
          
            <div class="swiper-pagination"></div>  
          
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
            

        </div>

      </div>
      <!-- cat-m2 end -->

    </div>
    <!-- main end  -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import { category, list } from '@/api/category'
import Swiper from "swiper";
import $ from 'jquery';

export default {
  components: {
    Header,
    Footer,
    Swiper,
  },
 

  data() {
    return {
     current:0,
     current2:0,
     current3:0,
     mySwiper: null,
	 swiper_num: -1,
	 banner: [],
	 category_name: '',
	 download_url: '',
	 project: [],
	 url_name: this.$route.query.category,
	 goodslist: [],

      swiperInstance: null,
    };
  },
 
 created() {
    this.category()
	this.list()
  },

  mounted() {
    
  },
  updated() {
    $(document).ready(function(){
      $(".tabItem-bar").mousedown(function(){
      $(this).parent().addClass("tabItem-stop");
      $(this).parent().next().addClass("tabItem-stop");
      $(this).attr("data-flag","1")
      })
      $(".tabItem-box").mousemove(function(e){
      var temp = $(this).find('.tabItem-bar').attr("data-flag");
      if(temp=="1"){
        var w = $(this).width();
		
        var x = e.offsetX;
        var p = parseFloat((x/w).toFixed(2))*100;
        $(this).children(".tabItem-img1").css('width',p+'%');
        $(this).children(".tabItem-img2").css('left',p+'%');
      }
      })
      $(document).mouseup(function(){
      $(".tabItem-img1,.tabItem-img2").removeClass("tabItem-stop");
      $(".tabItem-bar").attr("data-flag","0")
      })
	  
	  var swiperInstance = new Swiper('.swiper-container2', {
        spaceBetween: 40,
        slidesPerView: 'auto',
      });
    })
	
	
  },
  watch: {
    swiper_num(newdate,olddate){
	  var i_num = 0
	  if(newdate!=''){
	    i_num = newdate
	  }
	  else{
	    i_num = 0
	  }

	  var mySwiper = new Swiper(".swiper-container", {
	    	direction: "horizontal",
	    	loop: true,
            //autoplay: true,
	    	slidesPerView: 3,
			initialSlide : i_num,
	    	centeredSlides: true,
	    	spaceBetween: 20,
	    	observer: true,
	    	observeParents: true
		});
	  
	},
	$route: {
      handler(newVal, oldVal) {
        //判断newVal有没有值监听路由变化
        if (newVal != oldVal) {
          location.reload()
        }
      },
      deep: true
    }
  },
  methods: {
    // 分类选择
    category() {
      category().then(res => {
        this.banner = res.data.data
      }).catch(() => {})
    },
	// 列表
    list() {
      list({url_name:this.url_name}).then(res => {
        this.goodslist = res.data.data.list
		this.swiper_num=res.data.data.i_num
		this.category_name=res.data.data.category_name
		this.download_url=res.data.data.download
		this.project=res.data.data.project
		this.url_name=this.$route.query.category
      }).catch(() => {})
    },
    tabClick(k,index){
      this.current = index
	  $("#cat-conBox-left-"+k+" li").siblings('li').removeClass('active')
	  $("#cat-left-li-"+k+index).addClass('active')
	  $("#cat-conBox-right-"+k+" div").siblings('div').removeClass('isShow')
	  $("#cat-right-li-"+k+index).addClass('isShow')
    },
	Download(url){
	  if (url) {
         window.open(url);
      }
	}
  
  },
  
};


</script>

<style scoped >


.ny-main{
  overflow: hidden;
  background: url(https://api.starmaxintl.com/storage/file/20220825/fcc40106c75d778768863c8e332f6ab10d868255.png) 0 280px no-repeat;
 
}


.cat-banner-wrap .swiper-container {

  width: 100%;
  height: 400px;
  margin-bottom: 50px;
  overflow: visible !important;
}

.cat-banner-wrap .swiper-container .swiper-wrapper .swiper-slide,
.cat-banner-wrap .swiper-container .swiper-wrapper .swiper-slide-active {
  width: 800px;
}

.cat-banner-wrap .swiper-container .swiper-wrapper .swiper-slide img {
  width: 100%;
  height: 400px;
}

.cat-banner-wrap .swiper-container .swiper-wrapper .swiper-slide-next,
.cat-banner-wrap .swiper-container .swiper-wrapper .swiper-slide-prev,
.cat-banner-wrap .swiper-container .swiper-wrapper .swiper-slide-prev img,
.cat-banner-wrap .swiper-container .swiper-wrapper .swiper-slide-next img {
  height: 300px !important;
}


.cat-banner-wrap .swiper-pagination {
  bottom: -30px !important;
  display: none;
}

.cat-banner-wrap .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #0a3c5b;
}

.cat-banner-wrap .swiper-pagination .swiper-pagination-bullet-active {
  width: 21px;
  height: 12px;
  background: #0a3c5b;
  border-radius: 6px;
}

.cat-banner-name{
  display: block;
  min-height: 80px;
  padding:  25px 15px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
  line-height: 30px;
  color: #000;
  font-size: 24px;
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translate(-50% ,0);
  box-sizing: border-box;
}




.car-m1{
  padding: 0 8.3vw;
  overflow: hidden;
  display: flex;
}

.car-m1-left{
  width: 40%;
  min-width: 540px;
  overflow: hidden;
  margin-right: 20px;
}


.car-m1-left h3{
  line-height: 1;
  font-size: 3.75vw;
  color: #0a3c5b;
  white-space: pre-wrap;
  font-family: DINCound;
  font-weight: normal;
}


.car-m1-left p{
  line-height: 1.5;
  color: #000;
  font-size: 1.25vw;
  white-space: pre-wrap;
  margin: 25px 0;
}

.car-m1-left-btn1,
.car-m1-left-btn2{
  width: 230px;
  height: 100px;
  box-sizing: border-box;
  border: 1px solid #0a3c5b;
  margin-right: 30px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
}

.car-m1-left-btn1{
  background: #0a3c5b;
  padding: 13px 0;
}

.car-m1-left-btn1 p{
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  margin-top: 12px;
}


.car-m1-left-btn1 span{
  width: 72px;
  height: 72px;
  display: inline-block;
  float: left;
  margin-left: 30px;
}

.car-m1-left-btn1 span img{
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.car-m1-left-btn2{
  font-size: 24px;
  color: #0a3c5b;
  line-height: 36px;
  padding: 13px 0;
}

.car-m1-right p{
  line-height: 36px;
  font-size: 20px;
  color: #000000;
  margin-top: 3%;
}

.cat-con{
  margin-top: 80px
}


.cat-con-head{
  padding: 40px;
  text-align: center;
  overflow: hidden;
  border-bottom: 1px solid #dcdde1;
}


.cat-con-head h3{
  line-height: 1;
  font-size: 3.75vw;
  color: #0a3c5b;
  font-family: DINCound;
  font-weight: normal;
}

.cat-con-head P{
  color: #000;
  line-height: 1.5;
  margin-top: 15px;
  font-size: 1.25vw;
}

.cat-conBox-wrap{
  background: #fff;
  padding: 30px 8.3vw;
  margin-top: 4%;
}

.cat-conBox{
  width: 100%;
  position: relative;
}

.cat-conBox>h4{
  line-height: 40px;
  color: #0a3c5b;
  font-size: 32px;
  font-family: DINCound;
  font-weight: normal;
}

.cat-conBox>p{
  line-height: 36px;
  color: #0a3c5b;
  font-size: 18px;
  text-decoration: underline;
}

.cat-conTab{
  display: flex;
  margin-top: 30px;
}

.cat-conBox-left{
  width: 260px;
}

.cat-leftUl{
  margin-top: 20px;
}

.cat-leftUl li{
  height: 50px;
  overflow: hidden;
  margin-bottom: 15px;
}

.cat-leftUl-icon{
  display: inline-block;
  float: left;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 6px;
}

.cat-leftUl-icon img{
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.cat-leftUl li {
  line-height: 50px;
  color: #0a3c5b;
  font-size: 18px;
  cursor: pointer;
}

.cat-leftUl li.active,
.cat-leftUl li.active2,
.cat-leftUl li.active3{
  text-decoration: underline;
}



.cat-conBox-right{
  flex: 1;
 
}

.tabItem{
  display: none;
  height: 100%;
  position: relative;
}

.tabItem.isShow{
  display: block;
}

.tabItem-box{
  box-sizing: border-box;
  width: 900px;
  min-height: 600px;
  position: relative;
  margin: auto;
}

.tabItem-img1,
.tabItem-img2 {
  background-position: center center;
  background-size: 900px 600px;
  background-repeat: no-repeat;
  
}

.tabItem-img1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right:0;
  width: 100%;
  background-position-x: 0;
  z-index: 3;
}

.tabItem-img2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position-x: 100%;
  /* filter: invert(100%);*/
  
  
}

.tabItem-img1{
  width: 50%;
}


.tabItem-img2{
  left: 50%;
}


.tabItem-bar {
  position: absolute;
  top:0;
  bottom: 0;
  /* margin-top: -15px; */
  right: -58px;
  width: 117px;
  
  background: url(https://api.starmaxintl.com/storage/file/20221004/d3107531faa43730c0925dab9e12bed5aaa6e238.png) center no-repeat;
  cursor: ew-resize;
  background-size: 117px 57px;
}

.tabItem-stop {
  pointer-events: none;
}


.tabItem .car-m1-left-btn1{
  position: absolute;
  right: 0;
  bottom: -10px;
  z-index: 9;
  margin-right: 0;
  display: none;
}

.cat-m2{
  margin-top: 60px;
  margin-bottom: 90px;
}

.cat-m2-top{
  padding: 10px 8.3vw;
  overflow: hidden;
}

.cat-m2-top h3{
  line-height: 72px;
  font-size: 3.75vw;
  color: #0a3c5b;
  font-family: DINCound;
  font-weight: normal;
}

.cat-m2-topText{
  display: flex;
  flex-direction:column;
  justify-content: center;
  height: 72px;
}



.cat-m2-topText p{
  font-size: 20px;
  line-height: 24px;
  color: #000;
  display: inline-block;
}


.cat-m2-picBox{
  position: relative;
  margin-left: 8.3vw;
  height: 480px;
  margin-top: 30px;
  
}

.cat-m2-arrow{
    width: 54px;
    height: 54px;
    position: absolute;
    left: 35px;
    top: 50%;
    margin-top: -27px;
    z-index: 99;
}



.cat-m2-picBox .swiper-container2 {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.cat-m2-picBox .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 860px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.cat-m2-picBox .swiper-slide-active,
.cat-m2-picBox .swiper-slide-duplicate-active {
    z-index: 9;
}
  
.cat-m2-picBox .swiper-slide img {
    height: 100%;
    width: 100%;
}
  

.cat-m2-picBox .swiper-button-next:after,
.cat-m2-picBox .swiper-button-prev:after{
    content:'none'
}
  
.cat-m2-linkBtn{
  width: 210px;
  padding: 0 20px;
  height: 90px;
  line-height: 90px;
  font-size: 24px;
  color: #fff;
  position: absolute;
  bottom: 60px;
  text-align: center;
  z-index: 99;
  left: -4vw;
  background: #013656;
  cursor: pointer;
}


/* @media all and (max-width:1366px){
  .tabItem-box{
 
    width: 750px;
    height: 392px;
    margin: 41px auto;
  }

  .tabItem-img1,
  .tabItem-img2 {
    background-position: center center;
    background-size: 750px 392px;
    background-repeat: no-repeat;
    
  }

} */






</style>
